import { JSONContent } from "@tiptap/core";
import { Editor } from "@tiptap/react";

export const getMentionedUserIds = (editor: Editor): number[] => {
  return getMentionedIdsInner(editor.getJSON());
};

const getMentionedIdsInner = (json: JSONContent): number[] => {
  if (json.type === "mention") {
    return [Number(json.attrs!.id)];
  } else if (json.content) {
    return json.content.reduce(
      (prev: number[], curr) => [...prev, ...getMentionedIdsInner(curr)],
      [],
    );
  } else {
    return [];
  }
};
