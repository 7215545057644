import React, { FC, useMemo, useState, useCallback } from "react";
import { TiptapEditorContextState } from "@src/components/libraryItemDetailPages/course/contexts/TiptapEditorContext";

export interface TiptapEditorMapState {
  getTiptapEditorState: (key: string) => TiptapEditorContextState | null;
  setTiptapEditorState: (key: string, state: TiptapEditorContextState) => void;
  getFocusedEditor: () => TiptapEditorContextState | undefined;
  lastFocusedEditorId: string | undefined;
}

export const TiptapEditorMapContext = React.createContext<TiptapEditorMapState>(
  {
    getTiptapEditorState: () => null,
    setTiptapEditorState: () => null,
    getFocusedEditor: () => undefined,
    lastFocusedEditorId: undefined,
  },
);

type Props = {
  children: JSX.Element;
};
const TiptapEditorMapContextProvider: FC<Props> = ({ children }) => {
  const [lastFocusedEditorId, setLastFocusedEditorId] = useState<
    string | undefined
  >();
  const [map, setMap] = useState<{
    [key: string]: TiptapEditorContextState;
  }>({});
  const setTiptapEditorState = useCallback(
    (key: string, state: TiptapEditorContextState) => {
      setMap((prev) => ({ ...prev, [key]: state }));
      if (state.editorIsFocused && lastFocusedEditorId !== key) {
        setLastFocusedEditorId(key);
      }
    },
    [lastFocusedEditorId],
  );
  const getTiptapEditorState = useCallback(
    (key: string) => {
      return map[key];
    },
    [map],
  );
  const getFocusedEditor: TiptapEditorMapState["getFocusedEditor"] =
    useCallback(() => {
      return Object.values(map).find((v) => v.editorIsFocused);
    }, [map]);
  const contextState = useMemo(() => {
    return {
      getTiptapEditorState,
      setTiptapEditorState,
      getFocusedEditor,
      lastFocusedEditorId,
    };
  }, [
    getTiptapEditorState,
    setTiptapEditorState,
    getFocusedEditor,
    lastFocusedEditorId,
  ]);
  return (
    <TiptapEditorMapContext.Provider value={contextState}>
      {children}
    </TiptapEditorMapContext.Provider>
  );
};
export default TiptapEditorMapContextProvider;
