import { gql } from "@apollo/client";
import useUser from "./useUser";
import { BrandDetailsFragment } from "./useBranding.generated";
import { UserType } from "@src/types.generated";
import { useMemo } from "react";

type Return = {
  name: string | undefined | null;
  brandDetails: BrandDetailsFragment | null | undefined;
};
const useBranding = (): Return => {
  const { user } = useUser();
  return useMemo(() => {
    const brand =
      user?.userType !== UserType.Admin && user?.brands.length === 1
        ? user?.brands[0]
        : null;
    return brand
      ? {
          name: brand.name,
          brandDetails: brand.brandDetails || user?.org?.brandDetails,
        }
      : { name: user?.org?.name, brandDetails: user?.org?.brandDetails };
  }, [user]);
};

export default useBranding;

gql`
  fragment AIOrgContext on AIOrgContext {
    id
    foundingStory
    missionStatement
    businessType
    sells
    isApproved
  }
  fragment BrandDetails on BrandDetails {
    id
    logoUrls {
      id
      original
      medium
      thumb
    }
    aiBrandContext {
      ...AIOrgContext
    }
    brandColor
  }
`;
