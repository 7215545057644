import {
  SuggestionKeyDownProps,
  SuggestionOptions,
  SuggestionProps,
} from "@tiptap/suggestion";
import { PureEditorContent, ReactRenderer } from "@tiptap/react";
import MentionList from "@src/components/libraryItemDetailPages/course/build/comments/mentions/MentionList";
import tippy, { Instance, Props } from "tippy.js";
import gql from "graphql-tag";
import {
  AtMentionEmployeeFragment,
  CommentMentionPeopleQueryResult,
} from "@src/components/libraryItemDetailPages/course/build/comments/mentions/hooks/useMentionConfig.generated";
import { useApolloClient } from "@apollo/client";
import { Ref, useMemo } from "react";

const useMentionConfig = (
  ref: Ref<PureEditorContent> | undefined | null,
): Omit<SuggestionOptions, "editor"> => {
  const client = useApolloClient();
  return useMemo(
    () => ({
      items: async ({ query }) => {
        // @ts-ignore
        const { data }: CommentMentionPeopleQueryResult = await client.query({
          query: COMMENT_MENTIONS_PEOPLE_QUERY,
        });
        const people: AtMentionEmployeeFragment[] =
          data?.AdminEmployees.objects || [];
        return people
          .filter((item) =>
            item.name?.toLowerCase().startsWith(query.toLowerCase()),
          )
          .slice(0, 5);
      },

      render: () => {
        let component: ReactRenderer | undefined;
        let popup: Instance<Props>[] | undefined;

        return {
          onStart: (props: SuggestionProps) => {
            component = new ReactRenderer(MentionList, {
              props,
              editor: props.editor,
            });
            if (!ref) {
              return;
            }
            // @ts-ignore
            const current = ref.current?.editorContentRef?.current;
            if (current) {
              popup = tippy(current, {
                // @ts-ignore
                getReferenceClientRect: props.clientRect,
                appendTo: () => current,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
              });
            }
          },

          onUpdate(props: SuggestionProps) {
            if (component) {
              component.updateProps(props);
            }
            if (popup && popup.length > 0) {
              popup[0].setProps({
                // @ts-ignore
                getReferenceClientRect: props.clientRect,
              });
            }
          },

          onKeyDown(props: SuggestionKeyDownProps) {
            if (props.event.key === "Escape") {
              if (popup) {
                popup[0].hide();
              }

              return true;
            }
            if (component && component.ref) {
              // @ts-ignore
              return component.ref?.onKeyDown(props);
            }
          },

          onExit() {
            if (popup && popup.length > 0) {
              popup[0].destroy();
            }
            if (component) {
              component.destroy();
            }
          },
        };
      },
    }),
    [client, ref],
  );
};

export default useMentionConfig;

const COMMENT_MENTIONS_PEOPLE_QUERY = gql`
  query CommentMentionPeople {
    AdminEmployees(userTypes: [ADMIN, MANAGER]) {
      objects {
        ...AtMentionEmployee
      }
    }
  }

  fragment AtMentionEmployee on Employee {
    id
    name
  }
`;
