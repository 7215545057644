import Footer from "@src/deprecatedDesignSystem/components/Footer";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { useModal } from "@hooks/useModal";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { FC, useState } from "react";

type Props = {
  link: string;
  onComplete: (link: string) => void;
};
export const EditLinkModal: FC<Props> = ({
  link: initialLink = "",
  onComplete,
}) => {
  const { closeModal } = useModal();
  const [link, setLink] = useState(initialLink);
  return (
    <Modal
      width={500}
      header={<TitleHeader title="Add Link" onCancelClick={closeModal} />}
      footer={
        <Footer
          saveTitle="Save"
          isSaveDisabled={!link}
          onSaveClick={() => {
            onComplete(link.startsWith("http") ? link : `https://${link}`);
            closeModal();
          }}
        />
      }
    >
      <AutoLayout direction="vertical" padding={24}>
        <TextField label="Link" text={link} onTextChange={setLink} />
      </AutoLayout>
    </Modal>
  );
};

export default EditLinkModal;
