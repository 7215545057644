import {
  DeprecatedToneName,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type ColorValues = {
  backgroundColor: string;
  color: string;
};
type AvatarColors = {
  [key in DeprecatedToneName]: ColorValues;
};
export const AVATAR_COLOR_NAMES: DeprecatedToneName[] = [
  "blue",
  "purple",
  "green",
  "red",
  "orange",
  "gray",
];
export const avatarColors: AvatarColors = {
  blue: {
    backgroundColor: deprecatedTones.blue1,
    color: deprecatedTones.blue8,
  },
  purple: {
    backgroundColor: deprecatedTones.purple1,
    color: deprecatedTones.purple8,
  },
  green: {
    backgroundColor: deprecatedTones.green1,
    color: deprecatedTones.green8,
  },
  red: {
    backgroundColor: deprecatedTones.red1,
    color: deprecatedTones.red8,
  },
  orange: {
    backgroundColor: deprecatedTones.orange1,
    color: deprecatedTones.orange8,
  },
  yellow: {
    backgroundColor: deprecatedTones.yellow2,
    color: deprecatedTones.yellow10,
  },
  gray: {
    backgroundColor: deprecatedTones.gray1,
    color: deprecatedTones.gray8,
  },
};
