import React, { CSSProperties, FC, useCallback, useRef } from "react";
import { css, StyleDeclaration } from "aphrodite";
import useClickOutside from "@src/hooks/useClickOutside";
import { useModal } from "@src/hooks/useModal";
import { cn } from "@src/ui/lib/utils";

export const MODAL_Z_INDEX = 700;

type Props = {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  width?: React.CSSProperties["width"];
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
  closeOnClickOutside?: boolean;
  growHeight?: boolean;
  scrollContent?: boolean;
  dataTestId?: string;
  className?: string;
};

const Modal: FC<Props> = ({
  header,
  children,
  footer,
  width = "auto",
  styleDeclaration,
  closeOnClickOutside = false,
  style,
  growHeight,
  scrollContent = true,
  dataTestId,
  className,
}) => {
  const { closeModal } = useModal();
  const ref = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    if (closeOnClickOutside) {
      closeModal();
    }
  }, [closeOnClickOutside, closeModal]);

  useClickOutside(ref, onClickOutside);
  return (
    <div
      data-testid={dataTestId || "modal"}
      className={cn(
        "flex items-center justify-center z-[700]",
        growHeight && "self-stretch",
        !growHeight && "self-auto",
      )}
    >
      <div
        className={cn(
          "flex flex-col overflow-hidden rounded-lg shadow-lg bg-popover",
          css(styleDeclaration),
          className,
        )}
        style={{
          width,
          alignSelf: growHeight ? "stretch" : "unset",
          maxHeight: "max(90vh, 400px)",
          ...style,
        }}
        ref={ref}
      >
        {header}
        <div
          className={cn(
            "flex-1",
            scrollContent && "overflow-y-scroll",
            !scrollContent && "overflow-y-hidden",
          )}
        >
          {children}
        </div>
        {footer}
      </div>
    </div>
  );
};

export default Modal;
