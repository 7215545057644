import React, { FC, ReactElement } from "react";
import Button from "./Button";
import Text from "@ui/text";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { deprecatedTones } from "../styles/deprecatedColors";
import AutoLayout from "./AutoLayout";

type TitleHeaderProps = {
  title?: string;
  onCancelClick: () => void;
  onBackClick?: () => void;
  styleDeclaration?: StyleDeclaration;
  element?: ReactElement;
};

const TitleHeader: FC<TitleHeaderProps> = ({
  title,
  onCancelClick,
  onBackClick,
  styleDeclaration,
  element,
}) => {
  return (
    <div className={css(styles.container, styleDeclaration)}>
      {onBackClick && (
        <Button
          variant="No Outline"
          leftIcon="chevron-left"
          text="Back"
          onClick={onBackClick}
        />
      )}
      {!onBackClick && <div className={css(styles.spacer)}></div>}
      <AutoLayout direction="horizontal" alignmentVertical="center">
        {title && (
          <Text type="P2" fontWeight="SemiBold" color={deprecatedTones.black}>
            {title}
          </Text>
        )}
        {element}
      </AutoLayout>
      <div className={css(onBackClick && styles.largerButtonContainer)}>
        <Button variant="No Outline" leftIcon="close" onClick={onCancelClick} />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  spacer: {
    width: 24,
    height: 24,
  },
  largerButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 75,
  },
});

export default TitleHeader;
