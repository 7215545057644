import { usePrevious } from "@hooks/usePrevious";
import BoldExtension from "@tiptap/extension-bold";
import BulletListExtension from "@tiptap/extension-bullet-list";
import DocumentExtension from "@tiptap/extension-document";
import HardBreakExtension from "@tiptap/extension-hard-break";
import HistoryExtension from "@tiptap/extension-history";
import ItalicExtension from "@tiptap/extension-italic";
import LinkExtension from "@tiptap/extension-link";
import ListItemExtension from "@tiptap/extension-list-item";
import OrderedListExtension from "@tiptap/extension-ordered-list";
import ParagraphExtension from "@tiptap/extension-paragraph";
import PlaceholderExtension from "@tiptap/extension-placeholder";
import TextExtension from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import useMentionConfig from "@src/components/libraryItemDetailPages/course/build/comments/mentions/hooks/useMentionConfig";
import { Mention } from "@tiptap/extension-mention";
import { TiptapEditorContext } from "@src/components/libraryItemDetailPages/course/contexts/TiptapEditorContext";
import Text, { textVariants } from "@ui/text";
import { isRichTextEmpty } from "@src/components/libraryItemDetailPages/course/utils/utils";
import { LinkEditor } from "./LinkEditor";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import clsx from "clsx";
import { useBodyCursorType } from "@src/contexts/BodyCursorTypeContext";
import { cn } from "@src/ui/lib/utils";

type Props = {
  text: string;
  hasEditContentPermission: boolean;
  onChange?: (text: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
  styles?: StyleDeclaration;
  focusStyles?: StyleDeclaration;
  className?: string;
  autoFocus?: boolean;
  enableMentions?: boolean;
  animateTextChanges?: boolean;
  dataTestId?: string;
  transformPastedText?: (text: string) => string;
  listIndentation?: LIST_INDENTATION_LEVELS;
};

const TYPING_INTERVAL = 6;

const RichTextEditor: FC<Props> = ({
  text,
  hasEditContentPermission,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  placeholder = "Type here...",
  disabled,
  styles: styleOverrides,
  focusStyles: focusStyleOverrides,
  className,
  autoFocus = false,
  enableMentions = false,
  animateTextChanges = false,
  dataTestId,
  transformPastedText,
  listIndentation,
}) => {
  const textWithNewLines = text;
  const [newEditorValue, setNewEditorValue] = useState(textWithNewLines);
  const bodyCursorType = useBodyCursorType();
  useEffect(() => {
    if (!animateTextChanges) {
      return;
    }
    let i = newEditorValue.length;
    const intervalId = setInterval(() => {
      setNewEditorValue(textWithNewLines.slice(0, i));
      i++;
      if (i > textWithNewLines.length) {
        clearInterval(intervalId);
      }
    }, TYPING_INTERVAL);
    return () => clearInterval(intervalId);
  }, [animateTextChanges, newEditorValue.length, textWithNewLines]);
  const prevText = usePrevious(textWithNewLines);
  useEffect(() => {
    if (animateTextChanges) {
      return;
    }
    if (prevText !== textWithNewLines && textWithNewLines !== newEditorValue) {
      setNewEditorValue(textWithNewLines);
    }
  }, [textWithNewLines, prevText, newEditorValue, animateTextChanges]);
  const tiptapEditorContext = useContext(TiptapEditorContext);
  const mentionsConfig = useMentionConfig(tiptapEditorContext?.editorRef);
  const extensions = useMemo(() => {
    const extensions = [
      TextExtension,
      ParagraphExtension,
      DocumentExtension,
      BoldExtension,
      ItalicExtension,
      HardBreakExtension,
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Table.configure({
        HTMLAttributes: {
          class: css(styles.richTextTable),
        },
      }),
      TableRow.configure(),
      TableHeader.configure(),
      TableCell.configure({
        HTMLAttributes: {
          class: css(styles.richTextTableInner),
        },
      }),
      OrderedListExtension.configure({
        HTMLAttributes: {
          class: clsx(
            "list-decimal",
            listIndentation && LIST_INDENTATION_TO_CLASS_NAME[listIndentation],
          ),
        },
      }),
      ListItemExtension.configure({
        HTMLAttributes: {
          class: listIndentation
            ? LIST_INDENTATION_TO_CLASS_NAME[listIndentation]
            : undefined,
        },
      }),
      BulletListExtension.configure({
        HTMLAttributes: {
          class: "list-disc",
        },
      }),
      HistoryExtension,
      LinkExtension.extend({ inclusive: true }).configure({
        openOnClick: false,
        HTMLAttributes: {
          class: "rich-text-link",
        },
      }),
      PlaceholderExtension.configure({
        placeholder,
      }),
    ];
    if (enableMentions) {
      extensions.push(
        Mention.configure({
          HTMLAttributes: {
            class: "mention",
          },
          suggestion: mentionsConfig,
        }),
      );
    }
    return extensions;
  }, [enableMentions, listIndentation, mentionsConfig, placeholder]);
  const editorEditable =
    !disabled && !animateTextChanges && hasEditContentPermission;
  const editor = useEditor({
    extensions: extensions,
    content: animateTextChanges ? newEditorValue : textWithNewLines,
    onUpdate({ editor }) {
      const html = editor.getHTML();
      if (textWithNewLines !== html && !animateTextChanges) {
        if (isRichTextEmpty(html) && isRichTextEmpty(text)) return;
        onChange?.(html);
        setNewEditorValue(html);
      }
    },
    parseOptions: {
      preserveWhitespace: true,
    },
    onFocus: () => {
      onFocus();
      tiptapEditorContext?.setEditorIsFocused(true);
      if (bodyCursorType) {
        bodyCursorType.setCursorType("text");
      }
    },
    onBlur: () => {
      onBlur();
      tiptapEditorContext?.setEditorIsFocused(false);
      if (bodyCursorType) {
        bodyCursorType.setCursorType("auto");
      }
    },
    onTransaction: ({ editor }) => {
      tiptapEditorContext?.formatting.setIsBold(editor.isActive("bold"));
      tiptapEditorContext?.formatting.setIsItalic(editor.isActive("italic"));
      tiptapEditorContext?.formatting.setIsBulletList(
        editor.isActive("bulletList"),
      );
      tiptapEditorContext?.formatting.setIsOrderedList(
        editor.isActive("orderedList"),
      );
      tiptapEditorContext?.formatting.setIsTable(editor.isActive("table"));
    },
    editable: editorEditable,
    autofocus: autoFocus,
    editorProps: {
      transformPastedText: transformPastedText
        ? (text) => {
            return transformPastedText(text);
          }
        : undefined,
    },
  });
  useEffect(() => {
    editor?.setEditable(editorEditable);
  }, [editor, editorEditable]);
  useEffect(() => {
    if (animateTextChanges) {
      editor?.commands?.setContent(newEditorValue);
      return;
    }
    if (
      prevText !== textWithNewLines &&
      textWithNewLines !== editor?.getHTML()
    ) {
      editor?.commands?.setContent(textWithNewLines);
    }
  }, [animateTextChanges, textWithNewLines, prevText, editor, newEditorValue]);
  if (!tiptapEditorContext) {
    return <Text type={"P2"}>Please initialize RichTextEditorContext</Text>;
  }
  return (
    <>
      <EditorContent
        editor={editor}
        spellCheck
        className={cn(
          "flex max-w-full flex-1 border-none p-0",
          textVariants({ variant: "training-content" }),
          css(styleOverrides, editor?.isFocused && focusStyleOverrides),
          className,
        )}
        ref={tiptapEditorContext?.editorRef}
        value={newEditorValue}
        disabled={!editorEditable}
        placeholder={placeholder}
        data-testid={dataTestId || "rich-text-editor"}
        readOnly={animateTextChanges}
      />
      {editor && <LinkEditor editor={editor} />}
    </>
  );
};

type LIST_INDENTATION_LEVELS = 8 | 16 | 24;

export const LIST_INDENTATION_TO_CLASS_NAME: Record<
  LIST_INDENTATION_LEVELS,
  string
> = {
  8: "list-indentation-8",
  16: "list-indentation-16",
  24: "list-indentation-24",
};

const styles = StyleSheet.create({
  richTextTable: {
    border: `2px solid ${deprecatedTones.gray5Alpha}`,
    borderCollapse: "collapse",
    borderRadius: 12,
    width: "100%",
  },
  richTextTableInner: {
    border: `2px solid ${deprecatedTones.gray5Alpha}`,
    wordBreak: "break-word",
    minWidth: 60,
    minHeight: 60,
    padding: 4,
    fontSize: "14px",
    lineHeight: "18px",
  },
});

export default RichTextEditor;
