import { Editor, PureEditorContent } from "@tiptap/react";
import { useCallback, useMemo, useRef } from "react";
import { getMentionedUserIds } from "@utils/tiptap";

export type TipTapEditorRefUtils = {
  editorRef: React.RefObject<PureEditorContent>;
  editor: Editor | null | undefined;
  editorElement: Element;
  getMentionedUserIds: () => number[];
};

const useTipTapEditorRef = (): TipTapEditorRefUtils => {
  const editorRef = useRef<PureEditorContent>(null);
  const editor = editorRef?.current?.props?.editor;
  const editorElement = editorRef?.current?.editorContentRef?.current;
  const getMentionedUserIdsCallback = useCallback((): number[] => {
    if (!editor) {
      return [];
    }
    return getMentionedUserIds(editor);
  }, [editor]);
  return useMemo(
    () => ({
      editorRef,
      editor,
      editorElement,
      getMentionedUserIds: getMentionedUserIdsCallback,
    }),
    [editor, editorElement, getMentionedUserIdsCallback],
  );
};

export default useTipTapEditorRef;
