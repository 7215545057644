import { useContext } from "react";
import {
  TranslationModeContext,
  TranslationModeState,
} from "@src/components/libraryItemDetailPages/course/contexts/TranslationModeContextProvider";

const useTranslationModeContext = (): TranslationModeState => {
  const contextVal = useContext(TranslationModeContext);
  if (!contextVal) {
    throw Error("forgot to initialize TranslationModeContext");
  }
  return contextVal;
};

export default useTranslationModeContext;
