import React, { FC } from "react";
import Button from "./Button";
import { css, StyleSheet } from "aphrodite";
import { deprecatedTones } from "../styles/deprecatedColors";

export type FooterProps = {
  saveTitle?: string | null;
  onSaveClick?: () => void;
  isSaveDisabled?: boolean;
  isSaveLoading?: boolean;
  saveButtonDataTestId?: string;
  cancelTitle?: string;
  onCancelClick?: () => void;
  leftContent?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  primaryButton?: React.ReactNode;
  additionalButton?: React.ReactNode;
  hidePrimaryButton?: boolean;
};

const Footer: FC<FooterProps> = ({
  saveTitle,
  isSaveLoading = false,
  onSaveClick,
  isSaveDisabled = false,
  saveButtonDataTestId,
  cancelTitle,
  onCancelClick,
  leftContent,
  secondaryButton,
  primaryButton,
  additionalButton,
  hidePrimaryButton,
}) => {
  return (
    <div className={css(styles.container)}>
      {!!leftContent && <div>{leftContent}</div>}
      <div className={css(styles.actionsContainer)}>
        {!!cancelTitle && (
          <Button
            variant="Outline"
            height="40px"
            text={cancelTitle}
            onClick={onCancelClick}
            styleDeclaration={styles.secondaryButton}
          />
        )}
        {additionalButton}
        {secondaryButton}
        {!primaryButton && !hidePrimaryButton && saveTitle && (
          <Button
            variant="Primary"
            height="40px"
            text={saveTitle}
            onClick={onSaveClick}
            disabled={isSaveDisabled}
            loading={isSaveLoading}
            data-testid={saveButtonDataTestId}
          />
        )}
        {!hidePrimaryButton && primaryButton}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    borderTop: `1px solid ${deprecatedTones.gray5Alpha}`,
    backgroundColor: deprecatedTones.white,
  },
  secondaryButton: {
    marginRight: 8,
  },
  actionsContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

export default Footer;
