import { TranslationSetFragment } from "@src/fragments.generated";
import { isNotNullOrUndefined } from "@utils/typeguards";
import { PromptType } from "@src/types.generated";
import { v4 as uuidv4 } from "uuid";
import { CourseScreen, PromptScreen } from "../hooks/useCourseScreensAtom";
import { isObject } from "lodash";
import {
  CourseContentCourseVersionFragment,
  CourseContentPromptFragment,
} from "../build/CourseBuildContent.generated";
import {
  PathBuilderCourseFragment,
  PathBuilderPathVersionFragment,
  PathBuilderSkillFragment,
} from "../../module/ModuleDetailPageContainer.generated";
import { gql } from "@apollo/client";
import {
  PromptUtils_CourseVersionFragment,
  ResponseOptionUtils_CourseVersionFragment,
  LessonUtils_CourseVersionFragment,
} from "./utils.generated";
import { DueDateType } from "@src/deprecatedDesignSystem/components/DueDateInputField";
import { enUS } from "date-fns/locale";
import { formatDistanceToNowStrict } from "date-fns";
import { CommentFragment } from "@src/components/libraryItemDetailPages/course/build/comments/Comment.generated";

export const getResponseOption = <
  T extends ResponseOptionUtils_CourseVersionFragment,
>(
  responseOptionId: string,
  cv: T,
): T["lessons"][0]["prompts"][0]["responseOptions"][0] | undefined => {
  const responseOptions = getResponseOptions(cv);
  return responseOptions.find((ro) => ro.id === responseOptionId);
};
export const getPrompt = <T extends PromptUtils_CourseVersionFragment>(
  promptUuid: string,
  cv: T,
): T["lessons"][0]["prompts"][0] | undefined => {
  const prompts = getPrompts(cv);
  return prompts.find((p) => p.uuid === promptUuid)!;
};
export const getLesson = <T extends LessonUtils_CourseVersionFragment>(
  lessonUuid: string,
  cv: T,
): T["lessons"][0] | undefined => {
  return cv.lessons.find((l) => l.uuid === lessonUuid)!;
};
export const getLessonFromPromptUuid = <
  T extends PromptUtils_CourseVersionFragment,
>(
  promptUuid: string,
  cv: T,
): T["lessons"][0] | undefined => {
  return cv.lessons.find((lesson) =>
    lesson.prompts.find((prompt) => prompt.uuid === promptUuid),
  );
};

export const getPrompts = <T extends PromptUtils_CourseVersionFragment>(
  cv: T,
): T["lessons"][0]["prompts"] => {
  const reducer = (
    accumulator: T["lessons"][0]["prompts"],
    lesson: T["lessons"][0],
  ) => {
    return [...accumulator, ...lesson.prompts];
  };
  return cv.lessons.reduce(reducer, []);
};

export const getResponseOptions = <
  T extends ResponseOptionUtils_CourseVersionFragment,
>(
  cv: T,
): T["lessons"][0]["prompts"][0]["responseOptions"] => {
  const prompts = getPrompts(cv);
  const reducer = (
    accumulator: T["lessons"][0]["prompts"][0]["responseOptions"],
    prompt: T["lessons"][0]["prompts"][0],
  ) => {
    return [...accumulator, ...prompt.responseOptions];
  };
  return prompts.reduce(reducer, []);
};

export const getTranslationSets = (
  cv: CourseContentCourseVersionFragment,
): TranslationSetFragment[] => {
  const prompts = getPrompts(cv);
  const reducer = (
    accumulator: TranslationSetFragment[],
    prompt: CourseContentPromptFragment,
  ) => {
    const possibleTs = [prompt.text, prompt.correctFreeResponseAnswer];
    const nonNullTs = possibleTs.filter(isNotNullOrUndefined);
    return [...accumulator, ...nonNullTs];
  };
  return prompts.reduce(reducer, []);
};

export const getTranslationSet = (
  tsUuid: string,
  cv: CourseContentCourseVersionFragment,
): TranslationSetFragment | undefined => {
  const translationSets = getTranslationSets(cv);
  return translationSets.find((ts) => ts.uuid === tsUuid);
};

export const isRichTextEmpty = (text: string): boolean => {
  return text.replace(/<p><\/p>/g, "") === "";
};

export const isPromptEmpty = (prompt: CourseContentPromptFragment): boolean => {
  return (
    (!prompt.text || isRichTextEmpty(prompt.text.en)) &&
    prompt.type === PromptType.Message
  );
};

export const getPromptIndexInLesson = (
  promptUuid: string,
  cv: CourseContentCourseVersionFragment,
): number => {
  const lesson = getLessonFromPromptUuid(promptUuid, cv);
  if (!lesson) return -1;
  return lesson.prompts.findIndex((p) => p.uuid === promptUuid);
};

export const getCourse = (
  courseId: number,
  pv: PathBuilderPathVersionFragment,
): PathBuilderCourseFragment | undefined => {
  return (
    pv.pathContentMemberships.find((mem) => mem.course?.id === courseId)
      ?.course || undefined
  );
};

export const getSkill = (
  skillId: number,
  pv: PathBuilderPathVersionFragment,
): PathBuilderSkillFragment | undefined => {
  return (
    pv.pathContentMemberships.find((mem) => mem.skill?.id === skillId)?.skill ||
    undefined
  );
};

export const isCourseScreen = (
  courseScreen: unknown,
): courseScreen is CourseScreen => {
  if (!isObject(courseScreen)) {
    return false;
  }
  return (
    ["id", "index"].every((key) => key in courseScreen) &&
    ["promptScreen", "lessonScreen", "homeScreen"].some(
      (key) => key in courseScreen,
    )
  );
};

export const isPromptScreen = (
  courseScreen: CourseScreen,
): courseScreen is PromptScreen => {
  return "promptScreen" in courseScreen;
};

export function recursivelyReplaceIdsAndUuidsWithNewUuids<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(recursivelyReplaceIdsAndUuidsWithNewUuids) as never;
  }
  if (typeof obj === "object" && obj !== null) {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
      if (key === "id" || key === "uuid") {
        // @ts-ignore
        newObj[key] = uuidv4();
      } else {
        // @ts-ignore
        newObj[key] = recursivelyReplaceIdsAndUuidsWithNewUuids(newObj[key]);
      }
    });
    return newObj;
  }
  return obj;
}

gql`
  fragment LessonUtils_CourseVersion on CourseVersion {
    id
    lessons {
      uuid
    }
  }
  fragment PromptUtils_CourseVersion on CourseVersion {
    id
    lessons {
      uuid
      prompts {
        uuid
      }
    }
  }
  fragment ResponseOptionUtils_CourseVersion on CourseVersion {
    id
    lessons {
      uuid
      prompts {
        uuid
        responseOptions {
          id
        }
      }
    }
  }
`;

export const getDueDateType = (
  dueDate?: string | null,
  dueDateDays?: number | null,
): DueDateType => {
  if (dueDate) {
    return "Date";
  } else if (isNotNullOrUndefined(dueDateDays)) {
    return "Days";
  } else {
    return "None";
  }
};

export function formatPassingGradeDisplayValue(
  value: number | undefined | null,
): string {
  return value ? `${value}%` : "None";
}

export function getFormattedDistanceToNow(date: Date | number): string {
  const options = {
    locale: {
      ...enUS,
      formatDistance: (unit: string, count: number) => {
        switch (true) {
          case unit === "xDays":
            return `${count} day`;
          case unit === "xHours":
            return `${count} hr`;
          case unit === "xMinutes":
            return `${count} min`;
          case unit === "xMonths":
            return `${count} mon`;
          case unit === "xSeconds":
            return "just now";
          case unit === "xYears":
            return `${count} year`;
        }
        return "%d hr";
      },
    },
  };
  return formatDistanceToNowStrict(date, options);
}

type CommentCreatedBy = {
  email?: string | null;
  name?: string | null;
  firstName?: string | null;
};
export const getCommentCreatedByName = (
  createdBy?: CommentCreatedBy,
): string => {
  if (!createdBy) return "";
  if (createdBy.email?.endsWith("@opus.so")) {
    return `${createdBy.firstName} from Opus`;
  } else {
    return createdBy.name || "";
  }
};

export type CourseScreenCommentItem = {
  promptId: string;
  promptIndex: number;
  totalComments: number;
  mostRecentComment: CommentFragment;
};
export function sortAndFilterCourseScreensForCommentList(
  courseScreens: CourseScreen[],
): CourseScreenCommentItem[] {
  const promptScreensWithComments = courseScreens.filter((x) => {
    return "promptScreen" in x && x?.promptScreen?.commentThread;
  }) as PromptScreen[];
  const commentItems: CourseScreenCommentItem[] = [];
  promptScreensWithComments.forEach((screen) => {
    const screenComments = screen.promptScreen.commentThread?.comments;
    if (!screenComments || screenComments.length === 0) return;
    const mostRecentComment = screenComments.reduce((prev, current) => {
      return new Date(prev.createdAt) > new Date(current.createdAt)
        ? prev
        : current;
    });
    commentItems.push({
      promptId: screen.id,
      promptIndex: screen.promptScreen.promptPositionInLesson + 1,
      totalComments: screenComments.length,
      mostRecentComment,
    });
  });
  return commentItems.sort((a, b) => {
    return (
      new Date(b.mostRecentComment.createdAt).getTime() -
      new Date(a.mostRecentComment.createdAt).getTime()
    );
  });
}
