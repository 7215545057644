import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { useModal } from "@src/hooks/useModal";
import { Mark } from "@tiptap/pm/model";
import { BubbleMenu, Editor } from "@tiptap/react";
import { css, StyleSheet } from "aphrodite";
import { FC, useEffect, useState } from "react";
import EditLinkModal from "./EditLinkModal";
type Props = {
  editor: Editor;
};

export const LinkEditor: FC<Props> = ({ editor }) => {
  const [mark, setMark] = useState<Mark>();
  const { showModal } = useModal();
  useEffect(() => {
    const { $from } = editor.state.selection;
    const mark = $from.marks().find((mark: Mark) => mark.type.name === "link");
    if (!mark) {
      setMark(undefined);
      return;
    }
    setMark(mark);
  }, [editor.state.selection]);

  return (
    <BubbleMenu
      tippyOptions={{
        duration: 100,
        interactive: true,
        placement: "top",
      }}
      editor={editor}
      className={css(styles.bubbleMenu)}
      shouldShow={({ editor }) => {
        return !!editor.state.selection.$from
          .marks()
          .find((mark: Mark) => mark.type.name === "link");
      }}
    >
      <AutoLayout padding={4} direction="horizontal" alignmentVertical="center">
        <Text
          linkOpenInNewTab
          type="P2"
          fontWeight="SemiBold"
          link={mark?.attrs.href}
          color={deprecatedTones.gray10}
          style={{ marginRight: 8, width: 120, flex: 1 }}
          ellipsis
        >
          {mark?.attrs.href.replace(/(^\w+:|^)\/\//, "")}
        </Text>

        <Button
          variant="Gray"
          leftIcon="pencil"
          style={{ marginRight: 4 }}
          leftIconProps={{ color: deprecatedTones.black }}
          onClick={() => {
            showModal(
              <EditLinkModal
                link={mark?.attrs.href}
                onComplete={(link) => {
                  if (!mark) {
                    return;
                  }
                  editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .setLink({ href: link })
                    .run();
                }}
              />,
            );
          }}
        />

        <Button
          variant="Gray"
          leftIcon="trash-2"
          leftIconProps={{ color: deprecatedTones.black }}
          onClick={() => {
            editor
              .chain()
              .focus()
              .extendMarkRange("link")
              .toggleMark("link")
              .run();
          }}
        />
      </AutoLayout>
    </BubbleMenu>
  );
};

const styles = StyleSheet.create({
  bubbleMenu: {
    display: "flex",
    padding: "10px",
    borderRadius: 16,
    position: "relative",
    background: deprecatedTones.white,
    boxShadow:
      "0px 8px 64px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)",
  },
});
