import { FC, CSSProperties, useMemo } from "react";

import { DeprecatedToneName } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import {
  AVATAR_COLOR_NAMES,
  avatarColors,
} from "@src/deprecatedDesignSystem/styles/avatarColors";

type Props = {
  person: {
    name?: string | null | undefined;
    id: number;
  };
  color?: DeprecatedToneName;
  style?: CSSProperties;
  size?: number;
};

const Avatar: FC<Props> = ({ color, style, person, size = 30 }) => {
  const { name, id } = person;
  const initial = name?.trim()[0]?.toUpperCase() || " ";
  const parts: string[] = useMemo(() => (name ? name?.split(" ") : []), [name]);
  let lastLetter = "";
  if (parts.length > 1) {
    lastLetter = parts[parts.length - 1].charAt(0).toUpperCase() || "";
  }
  const avatarColorIndex = id % AVATAR_COLOR_NAMES.length;
  const colorFromId = AVATAR_COLOR_NAMES[avatarColorIndex];
  const avatarColorValues = color
    ? avatarColors[color]
    : avatarColors[colorFromId];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexShrink: 0,
        height: size,
        width: size,
        borderRadius: size / 2,
        color: avatarColorValues.color,
        backgroundColor: avatarColorValues.backgroundColor,
        fontSize: size / 2.3,
        fontWeight: 600,
        lineHeight: `${size}px`,
        ...(style || {}),
      }}
    >
      {initial}
      {lastLetter}
    </div>
  );
};

export default Avatar;
