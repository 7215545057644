import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { SuggestionProps } from "@tiptap/suggestion";
import { css, StyleSheet } from "aphrodite";
import FlexRow from "@components/ui/flexBox/FlexRow";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Avatar from "@components/ui/Avatar";
import Spacer from "@components/ui/Spacer";

const MentionList = (
  props: SuggestionProps,
  ref: React.RefObject<HTMLDivElement>,
) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item.id, label: item.name });
    }
  };
  useEffect(() => setSelectedIndex(0), [props.items]);
  // @ts-ignore
  useImperativeHandle(ref, () => ({
    // @ts-ignore
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
        return true;
      }
      if (event.key === "ArrowDown") {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
        return true;
      }
      if (event.key === "Enter") {
        selectItem(selectedIndex);
        return true;
      }
      return false;
    },
  }));

  return (
    <div className={css(styles.menuContainer)}>
      {props.items.length ? (
        props.items.map((item, index) => (
          <FlexRow
            key={item.id}
            styleDeclaration={[
              styles.menuRow,
              index === selectedIndex && styles.menuRowSelected,
            ]}
            onClick={() => {
              selectItem(index);
            }}
          >
            <Avatar person={item} size={18} />
            <Spacer size={4} axis={"horizontal"} />
            {item.name}
          </FlexRow>
        ))
      ) : (
        // eslint-disable-next-line tailwindcss/no-custom-classname
        <div className="item" style={{ width: 175, padding: "3px 12px" }}>
          No result
        </div>
      )}
    </div>
  );
};

// @ts-ignore
export default forwardRef(MentionList);

const styles = StyleSheet.create({
  menuContainer: {
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 8,
    border: "1px solid",
    borderColor: deprecatedTones.gray5Alpha,
    backgroundColor: deprecatedTones.white,
    boxShadow:
      "rgb(0 0 0 / 4%) 0px 16px 32px, rgb(0 0 0 / 4%) 0px 8px 16px, rgb(0 0 0 / 4%) 0px 4px 8px, rgb(0 0 0 / 4%) 0px 2px 4px, rgb(0 0 0 / 4%) 0px 1px 2px ",
  },
  menuRow: {
    fontSize: 14,
    padding: "3px 12px",
    fontWeight: 400,
    width: 175,
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  menuRowSelected: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});
