import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import useTipTapEditorRef, {
  TipTapEditorRefUtils,
} from "@hooks/useTipTapEditorRef";
import { TiptapEditorMapContext } from "@src/components/libraryItemDetailPages/course/contexts/TiptapEditorMapContext";

export interface FormattingState {
  isBold: boolean;
  setIsBold: (isBold: boolean) => void;
  isItalic: boolean;
  setIsItalic: (isItalic: boolean) => void;
  isOrderedList: boolean;
  setIsOrderedList: (isOrderedList: boolean) => void;
  isBulletList: boolean;
  setIsBulletList: (isBulletList: boolean) => void;
  isTable: boolean;
  setIsTable: (isTable: boolean) => void;
}

export type TiptapEditorContextState = TipTapEditorRefUtils & {
  editorId: string | undefined;
  formatting: FormattingState;
  editorIsFocused: boolean;
  setEditorIsFocused: (isFocused: boolean) => void;
};

export const TiptapEditorContext = React.createContext<
  TiptapEditorContextState | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
  tiptapEditorId?: string;
};

const TiptapEditorContextProvider: FC<Props> = (props) => {
  const editorRefUtils = useTipTapEditorRef();
  const tiptapEditorMapContext = useContext(TiptapEditorMapContext);
  const [editorIsFocused, setEditorIsFocused] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isOrderedList, setIsOrderedList] = useState(false);
  const [isBulletList, setIsBulletList] = useState(false);
  const [isTable, setIsTable] = useState(false);
  const formattingState: FormattingState = useMemo(() => {
    return {
      isBold,
      setIsBold,
      isItalic,
      setIsItalic,
      isOrderedList,
      setIsOrderedList,
      isBulletList,
      setIsBulletList,
      isTable,
      setIsTable,
    };
  }, [
    isBold,
    setIsBold,
    isItalic,
    setIsItalic,
    isOrderedList,
    setIsOrderedList,
    isBulletList,
    setIsBulletList,
    isTable,
    setIsTable,
  ]);
  const contextState: TiptapEditorContextState = useMemo(() => {
    return {
      ...editorRefUtils,
      formatting: formattingState,
      editorIsFocused,
      setEditorIsFocused,
      editorId: props.tiptapEditorId,
    };
  }, [editorIsFocused, editorRefUtils, formattingState, props.tiptapEditorId]);
  useEffect(() => {
    if (!props.tiptapEditorId) return;
    tiptapEditorMapContext?.setTiptapEditorState(
      props.tiptapEditorId,
      contextState,
    );
  }, [props.tiptapEditorId, contextState]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <TiptapEditorContext.Provider value={contextState}>
      {props.children}
    </TiptapEditorContext.Provider>
  );
};

export default TiptapEditorContextProvider;
