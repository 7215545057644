import { useAvailableLanguages } from "@hooks/useAvailableLanguages";
import React, { FC, useCallback, useMemo } from "react";
import {
  BooleanParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

export interface TranslationModeState {
  currentLang: Language;
  translationModeEnabled: boolean;
  setTranslationModeEnabled: (val: boolean) => void;
  translationLanguage: Language;
  setTranslationLanguage: (val: Language) => void;
}

export const TranslationModeContext = React.createContext<
  TranslationModeState | undefined
>(undefined);

type Props = {
  children: React.ReactNode;
};
const TranslationModeContextProvider: FC<Props> = ({ children }) => {
  const { mostPopularNonEnglishLanguage: initialTranslateLanguage } =
    useAvailableLanguages();

  const [enabled, setEnabled] = useQueryParam(
    "translationMode",
    withDefault(BooleanParam, false),
  );

  const [lang, setLang] = useQueryParam(
    "translationLang",
    withDefault(StringParam, initialTranslateLanguage),
  );

  const setTranslationModeEnabled = useCallback(
    (val: boolean) => {
      setEnabled(val);
    },
    [setEnabled],
  );
  const setTranslationLanguage = useCallback(
    (val: Language) => {
      setLang(val);
    },
    [setLang],
  );
  const contextState = useMemo(() => {
    return {
      translationModeEnabled: enabled,
      translationLanguage: lang as Language,
      setTranslationModeEnabled,
      setTranslationLanguage,
      currentLang: enabled ? (lang as Language) : "en",
    };
  }, [enabled, lang, setTranslationModeEnabled, setTranslationLanguage]);
  return (
    <TranslationModeContext.Provider value={contextState}>
      {children}
    </TranslationModeContext.Provider>
  );
};
export default TranslationModeContextProvider;
